exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-redirect-ts": () => import("./../../../src/pages/redirect.ts" /* webpackChunkName: "component---src-pages-redirect-ts" */),
  "component---src-templates-basic-page-tsx": () => import("./../../../src/templates/BasicPage.tsx" /* webpackChunkName: "component---src-templates-basic-page-tsx" */),
  "component---src-templates-event-tsx": () => import("./../../../src/templates/Event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/News.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/Product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

